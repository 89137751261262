import Axios from 'axios'
import { ThunkDispatch } from 'redux-thunk'
import { AppDispatch } from '../..'
import apoint from '../../apoint'
import { axiosConfig } from '../../axios_config'
import { handleNotification, NotificationBarColours } from './notification'

//Synchronous actions
export const registerLoadingAction = (): { type: string, payload: { isLoading: boolean, success: boolean, error: boolean } } => ({
    type: 'REGISTER_LOADING',
    payload: {
        error: false,
        success: false,
        isLoading: true
    }
})

export const registerSuccessAction = (): { type: string, payload: { isLoading: boolean, success: boolean, error: boolean } } => ({
    type: 'REGISTER_SUCCESS',
    payload: {
        isLoading: false,
        success: true,
        error: false
    }
})

export const registerErrorAction = (): { type: string, payload: { isLoading: boolean, success: boolean, error: boolean } } => ({
    type: 'REGISTER_ERROR',
    payload: {
        isLoading: false,
        success: false,
        error: true
    }
})

export interface QuizTaker {
    firstname: string
    lastname: string
    email: string
    country: string
    recapToken: string
}

//Asynchronous actions
export const registerQuizTaker = (quizTaker: QuizTaker) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return async (dispatch: ThunkDispatch<AppDispatch, void, any>): Promise<{ type: string, payload: { isLoading: boolean, success: boolean, error: boolean } }> => {
        dispatch(registerLoadingAction())
        
        try {
            const registerQT = await Axios.post(`${apoint.register}`, { ...quizTaker }, axiosConfig)
            if (registerQT.status === 200) {
                dispatch(handleNotification({ notificationMessage: 'Verification email sent! Check your Spam folder or re-enter your details to resend.', colour: NotificationBarColours.green }))
                return dispatch(registerSuccessAction())
            }
            dispatch(handleNotification({notificationMessage: 'An error occurred. Please try again later.', colour: NotificationBarColours.red}))
            return dispatch(registerErrorAction())
        } catch (err) {
            dispatch(handleNotification({notificationMessage: 'An error occurred. Please try again later.', colour: NotificationBarColours.red}))
            return dispatch(registerErrorAction())
        }
    }
}