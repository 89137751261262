export const registerReducer = (state = { success: false, isLoading: false, error: false }, action: { type: string, payload: { isLoading: boolean, error: boolean, success: boolean } }): { isLoading: boolean, success: boolean, error: boolean } => {
    switch (action.type) {
        case 'REGISTER_LOADING':
            return action.payload
        case 'REGISTER_SUCCESS':
            return action.payload
        case 'REGISTER_ERROR':
            return action.payload
        default:
            return state
    }
}