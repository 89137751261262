import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../..'
import notif from './notification.module.css'

const Notification = function (): React.ReactElement {

    const animateIn = () => {
        const element = document.getElementById("notifbar")
        let right = -300
        const move = setInterval(frame, 0.1);
        function frame() {
            if (right === 10) {
                clearInterval(move)
            } else {
                right += 10
                if (element !== null) {
                    element.style.right = right + 'px'
                }
            }
        }
    }

    const animateOut = () => {
        const element = document.getElementById("notifbar")
        let right = 10
        const move = setInterval(frame, 0.1);
        function frame() {
            if (right === -300) {
                clearInterval(move)
            } else {
                right -= 10
                if (element !== null) {
                    element.style.right = right + 'px'
                }
            }
        }
    }

    const notification = useSelector((state: ApplicationState) => state.notification)

    useEffect(() => {
        if (notification.showNotification === 'true') {
            animateIn()
            return
        }
        if (notification.showNotification === 'false') {
            animateOut()
            return
        }

    }, [notification.showNotification])

    return (
        <div id="notifbar" aria-label={notification.notificationMessage} className={notif.notifcontainer} style={{ borderBottom: `5px solid ${notification.colour}` }}>
            {notification.notificationMessage}
        </div>
    )
}

export default Notification