// import axios from 'axios'

//axios config to set headers
//credentials allow the browser to send cookies with every request
export const axiosConfig = {
    headers: {
        "Content-Type": "application/json"
    },
    withCredentials: true
}

//Can populate this file with axios interceptors if it's required in the future