import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
// import NavBar from './components/navbar/navbar';
import MainContent from './components/maincontent/maincontent';
import AdditionalContent from './components/additionalcontent/additionalcontent';
import Footer from './components/footer/footer';

function App(): React.ReactElement {
  return (
    <>
      <div className="container-fluid" id="parentContainer" style={{ position: 'relative', overflow: 'hidden', padding: '0px' }}>
        {/* <NavBar /> */}
        <MainContent />
        <AdditionalContent />
        <Footer />
      </div>
    </>
  );
}

export default App;
