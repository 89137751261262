import { combineReducers } from 'redux'

import { registerReducer } from './registerReducer'
import { notificationReducer } from './notificationReducer'

const reducers = combineReducers({
    notification: notificationReducer,
    registerQuizTaker: registerReducer
})

export default reducers