import React from "react";

const TermsModal = function (): React.ReactElement {
    return (
        <div
            className="modal fade"
            id="termsModal"
            tabIndex={-1}
            aria-labelledby="termsModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="termsModalLabel">
                            Study for Your Citizenship Terms of Service
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h3>TERMS OF SERVICE</h3>
                        <p>Last updated: January 14, 2023</p>
                        <h5>TABLE OF CONTENTS</h5>
                        <ol>
                            <h6>
                                <a href="#agreement">
                                    <li>AGREEMENT TO TERMS</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#ipr">
                                    <li>INTELLECTUAL PROPERTY RIGHTS</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#ur">
                                    <li>USER REPRESENTATIONS</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#ureg">
                                    <li>USER REGISTRATION</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#pra">
                                    <li>PROHIBITED ACTIVITIES</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#ugc">
                                    <li>USER GENERATED CONTRIBUTIONS</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#conli">
                                    <li>CONTRIBUTION LICENSE</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#subm">
                                    <li>SUBMISSIONS</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#tpwc">
                                    <li>THIRD-PARTY WEBSITE AND CONTENT</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#smng">
                                    <li>SITE MANAGEMENT</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#pripol">
                                    <li>PRIVACY POLICY</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#refpol">
                                    <li>REFUND POLICY</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#tte">
                                    <li>TERM AND TERMINATION</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#moint">
                                    <li>MODIFICATIONS AND INTERRUPTIONS</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#govl">
                                    <li>GOVERNING LAW</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#dres">
                                    <li>DISPUTE RESOLUTION</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#cor">
                                    <li>CORRECTIONS</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#pap">
                                    <li>PURCHASES</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#disc">
                                    <li>DISCLAIMER</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#limli">
                                    <li>LIMITATIONS OF LIABILITY</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#indem">
                                    <li>INDEMNIFICATION</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#ud">
                                    <li>USER DATA</li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#ecg">
                                    <li>
                                        ELECTRONIC COMMUNICATIONS, GOODS, TRANSACTIONS, AND
                                        SIGNATURES
                                    </li>
                                </a>
                            </h6>
                            <h6>
                                <a href="#cont">
                                    <li>CONTACT US</li>
                                </a>
                            </h6>
                        </ol>

                        <h5 id="agreement">1. AGREEMENT TO TERMS</h5>
                        <p>
                            These Terms of Use constitute a legally binding agreement made
                            between you, whether personally or on behalf of an entity ("you")
                            and Study for Your Citizenship , doing business as Study fYC or
                            SFYC ("Study fYC", "SFYC" ,"we,"us," or "our"), concerning your
                            access to and use of the studyfyc.com website as well as any other
                            media form, media channel, mobile website or mobile application
                            related, linked, or otherwise connected thereto (collectively, the
                            "Site"). You agree that by accessing the Site, you have read,
                            understood, and agreed to be bound by all of these Terms of Use.
                            IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE
                            EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE
                            USE IMMEDIATELY. Supplemental terms and conditions or documents
                            that may be posted on the Site from time to time are hereby
                            expressly incorporated herein by reference. We reserve the right,
                            in our sole discretion, to make changes or modifications to these
                            Terms of Use from time to time. We will alert you about any
                            changes by updating the "Last updated" date of these Terms of Use,
                            and you waive any right to receive specific notice of each such
                            change. Please ensure that you check the applicable Terms every
                            time you use our Site so that you understand which Terms apply.
                            You will be subject to, and will be deemed to have been made aware
                            of and to have accepted, the changes in any revised Terms of Use
                            by your continued use of the Site after the date such revised
                            Terms of Use are posted. The information provided on the Site is
                            not intended for distribution to or use by any person or entity in
                            any jurisdiction or country where such distribution or use would
                            be contrary to law or regulation or which would subject us to any
                            registration requirement within such jurisdiction or country.
                            Accordingly, those persons who choose to access the Site from
                            other locations do so on their own initiative and are solely
                            responsible for compliance with local laws, if and to the extent
                            local laws are applicable. The Site is not tailored to comply with
                            industry-specific regulations (Health Insurance Portability and
                            Accountability Act (HIPAA), Federal Information Security
                            Management Act (FISMA), etc.), so if your interactions would be
                            subjected to such laws, you may not use this Site. You may not use
                            the Site in a way that would violate the Gramm-Leach-Bliley Act
                            (GLBA). The Site is intended for users who are at least 18 years
                            old. Persons under the age of 18 are not permitted to use or
                            register for the Site.
                        </p>
                        <h5 id="ipr">2. INTELLECTUAL PROPERTY RIGHTS</h5>
                        <p>
                            Unless otherwise indicated, the Site is our proprietary property
                            and all source code, databases, functionality, software, website
                            designs, audio, video, text, photographs, and graphics on the Site
                            (collectively, the "Content") and the trademarks, service marks,
                            and logos contained therein (the "Marks") are owned or controlled
                            by us or licensed to us, and are protected by copyright and
                            trademark laws and various other intellectual property rights and
                            unfair competition laws of the United States, international
                            copyright laws, and international conventions. The Content and the
                            Marks are provided on the Site "AS IS" for your information and
                            personal use only. Except as expressly provided in these Terms of
                            Use, no part of the Site and no Content or Marks may be copied,
                            reproduced, aggregated, republished, uploaded, posted, publicly
                            displayed, encoded, translated, transmitted, distributed, sold,
                            licensed, or otherwise exploited for any commercial purpose
                            whatsoever, without our express prior written permission. Provided
                            that you are eligible to use the Site, you are granted a limited
                            license to access and use the Site and to download or print a copy
                            of any portion of the Content to which you have properly gained
                            access solely for your personal, non-commercial use. We reserve
                            all rights not expressly granted to you in and to the Site, the
                            Content and the Marks.
                        </p>
                        <h5 id="ur">3. USER REPRESENTATIONS</h5>
                        <p>
                            By using the Site, you represent and warrant that: (1) all
                            registration information you submit will be true, accurate,
                            current, and complete; (2) you will maintain the accuracy of such
                            information and promptly update such registration information as
                            necessary ; (3) you have the legal capacity and you agree to
                            comply with these Terms of Use; (4) you are not a minor in the
                            jurisdiction in which you reside ; (5) you will not access the
                            Site through automated or non-human means, whether through a bot,
                            script, or otherwise; (6) you will not use the Site for any
                            illegal or unauthorized purpose; and (7) your use of the Site will
                            not violate any applicable law or regulation. If you provide any
                            information that is untrue, inaccurate, not current, or
                            incomplete, we have the right to suspend or terminate your account
                            and refuse any and all current or future use of the Site (or any
                            portion thereof).
                        </p>
                        <h5 id="ureg">4. USER REGISTRATION</h5>
                        <p>
                            You may be required to register with the Site. You agree to keep
                            your password confidential and will be responsible for all use of
                            your account and password. We reserve the right to remove,
                            reclaim, or change a username you select if we determine, in our
                            sole discretion, that such username is inappropriate, obscene, or
                            otherwise objectionable.
                        </p>
                        <h5 id="pra">5. PROHIBITED ACTIVITIES</h5>
                        <p>
                            You may not access or use the Site for any purpose other than that
                            for which we make the Site available. The Site may not be used in
                            connection with any commercial endeavors except those that are
                            specifically endorsed or approved by us. As a user of the Site,
                            you agree not to:
                        </p>
                        <ul>
                            <li>
                                Systematically retrieve data or other content from the Site to
                                create or compile, directly or indirectly, a collection,
                                compilation, database, or directory without written permission
                                from us.
                            </li>
                            <li>
                                Trick, defraud, or mislead us and other users, especially in
                                any attempt to learn sensitive account information such as
                                user passwords.
                            </li>
                            <li>
                                Circumvent, disable, or otherwise interfere with
                                security-related features of the Site, including features that
                                prevent or restrict the use or copying of any Content or
                                enforce limitations on the use of the Site and/or the Content
                                contained therein.
                            </li>
                            <li>
                                Disparage, tarnish, or otherwise harm, in our opinion, us
                                and/or the Site.
                            </li>
                            <li>
                                Use any information obtained from the Site in order to harass,
                                abuse, or harm another person.
                            </li>
                            <li>
                                Make improper use of our support services or submit false
                                reports of abuse or misconduct.
                            </li>
                            <li>
                                Use the Site in a manner inconsistent with any applicable laws
                                or regulations.
                            </li>
                            <li>
                                Engage in unauthorized framing of or linking to the Site.
                            </li>
                            <li>
                                Upload or transmit (or attempt to upload or to transmit)
                                viruses, Trojan horses, or other material, including excessive
                                use of capital letters and spamming (continuous posting of
                                repetitive text), that interferes with any party's
                                uninterrupted use and enjoyment of the Site or modifies,
                                impairs, disrupts, alters, or interferes with the use,
                                features, functions, operation, or maintenance of the Site.
                            </li>
                            <li>
                                Engage in any automated use of the system, such as using
                                scripts to send comments or messages, or using any data
                                mining, robots, or similar data gathering and extraction
                                tools.
                            </li>
                            <li>
                                Delete the copyright or other proprietary rights notice from
                                any Content.
                            </li>
                            <li>
                                Attempt to impersonate another user or person or use the
                                username of another user.
                            </li>
                            <li>
                                Upload or transmit (or attempt to upload or to transmit) any
                                material that acts as a passive or active information
                                collection or transmission mechanism, including without
                                limitation, clear graphics interchange formats ('gifs'), 1x1
                                pixels, web bugs, cookies, or other similar devices (sometimes
                                referred to as 'spyware' or 'passive collection mechanisms' or
                                'pcms').
                            </li>
                            <li>
                                Interfere with, disrupt, or create an undue burden on the Site
                                or the networks or services connected to the Site.
                            </li>
                            <li>
                                Harass, annoy, intimidate, or threaten any of our employees or
                                agents engaged in providing any portion of the Site to you.
                            </li>
                            <li>
                                Attempt to bypass any measures of the Site designed to prevent
                                or restrict access to the Site, or any portion of the Site.
                            </li>
                            <li>
                                Copy or adapt the Site's software, including but not limited
                                to Flash, PHP, HTML, JavaScript, or other code.
                            </li>
                            <li>
                                Except as permitted by applicable law, decipher, decompile,
                                disassemble, or reverse engineer any of the software
                                comprising or in any way making up a part of the Site.
                            </li>
                            <li>
                                Except as may be the result of standard search engine or
                                Internet browser usage, use, launch, develop, or distribute
                                any automated system, including without limitation, any
                                spider, robot, cheat utility, scraper, or offline reader that
                                accesses the Site, or using or launching any unauthorized
                                script or other software.
                            </li>
                            <li>
                                Use a buying agent or purchasing agent to make purchases on
                                the Site.
                            </li>
                            <li>
                                Make any unauthorized use of the Site, including collecting
                                usernames and/or email addresses of users by electronic or
                                other means for the purpose of sending unsolicited email, or
                                creating user accounts by automated means or under false
                                pretenses.
                            </li>
                            <li>
                                Use the Site as part of any effort to compete with us or
                                otherwise use the Site and/or the Content for any
                                revenue-generating endeavor or commercial enterprise.
                            </li>
                        </ul>
                        <h5 id="ugc">6. USER GENERATED CONTRIBUTIONS</h5>
                        <p>
                            The Site does not offer users to submit or post content. We may
                            provide you with the opportunity to create, submit, post, display,
                            transmit, perform, publish, distribute, or broadcast content and
                            materials to us or on the Site, including but not limited to text,
                            writings, video, audio, photographs, graphics, comments,
                            suggestions, or personal information or other material
                            (collectively, "Contributions"). Contributions may be viewable by
                            other users of the Site and through third-party websites. As such,
                            any Contributions you transmit may be treated in accordance with
                            the Site Privacy Policy. When you create or make available any
                            Contributions, you thereby represent and warrant that:
                        </p>
                        <ul>
                            <li>
                                The creation, distribution, transmission, public display, or
                                performance, and the accessing, downloading, or copying of
                                your Contributions do not and will not infringe the
                                proprietary rights, including but not limited to the
                                copyright, patent, trademark, trade secret, or moral rights of
                                any third party.
                            </li>
                            <li>
                                You are the creator and owner of or have the necessary
                                licenses, rights, consents, releases, and permissions to use
                                and to authorize us, the Site, and other users of the Site to
                                use your Contributions in any manner contemplated by the Site
                                and these Terms of Use.
                            </li>
                            <li>
                                You have the written consent, release, and/or permission of
                                each and every identifiable individual person in your
                                Contributions to use the name or likeness of each and every
                                such identifiable individual person to enable inclusion and
                                use of your Contributions in any manner contemplated by the
                                Site and these Terms of Use.
                            </li>
                            <li>
                                Your Contributions are not false, inaccurate, or misleading.
                            </li>
                            <li>
                                Your Contributions are not unsolicited or unauthorized
                                advertising, promotional materials, pyramid schemes, chain
                                letters, spam, mass mailings, or other forms of solicitation.
                            </li>
                            <li>
                                Your Contributions are not obscene, lewd, lascivious, filthy,
                                violent, harassing, libelous, slanderous, or otherwise
                                objectionable (as determined by us).
                            </li>
                            <li>
                                Your Contributions do not ridicule, mock, disparage,
                                intimidate, or abuse anyone.
                            </li>
                            <li>
                                Your Contributions are not used to harass or threaten (in the
                                legal sense of those terms) any other person and to promote
                                violence against a specific person or class of people.
                            </li>
                            <li>
                                Your Contributions do not violate any applicable law,
                                regulation, or rule.
                            </li>
                            <li>
                                Your Contributions do not violate the privacy or publicity
                                rights of any third party.
                            </li>
                            <li>
                                Your Contributions do not violate any applicable law
                                concerning child pornography, or otherwise intended to protect
                                the health or well-being of minors.
                            </li>
                            <li>
                                Your Contributions do not include any offensive comments that
                                are connected to race, national origin, gender, sexual
                                preference, or physical handicap.
                            </li>
                            <li>
                                Your Contributions do not otherwise violate, or link to
                                material that violates, any provision of these Terms of Use,
                                or any applicable law or regulation.
                            </li>
                        </ul>
                        <p>
                            Any use of the Site in violation of the foregoing violates these
                            Terms of Use and may result in, among other things, termination or
                            suspension of your rights to use the Site.
                        </p>
                        <h5 id="conli">7. CONTRIBUTION LICENSE</h5>
                        <p>
                            You agree that we may access, store, process, and use any
                            information and personal data that you provide following the terms
                            of the Privacy Policy and your choices (including settings). By
                            submitting suggestions or other feedback regarding the Site, you
                            agree that we can use and share such feedback for any purpose
                            without compensation to you. We do not assert any ownership over
                            your Contributions. You retain full ownership of all of your
                            Contributions and any intellectual property rights or other
                            proprietary rights associated with your Contributions. We are not
                            liable for any statements or representations in your Contributions
                            provided by you in any area on the Site. You are solely
                            responsible for your Contributions to the Site and you expressly
                            agree to exonerate us from any and all responsibility and to
                            refrain from any legal action against us regarding your
                            Contributions.
                        </p>
                        <h5 id="subm">8. SUBMISSIONS</h5>
                        <p>
                            You acknowledge and agree that any questions, comments,
                            suggestions, ideas, feedback, or other information regarding the
                            Site ("Submissions") provided by you to us are non-confidential
                            and shall become our sole property. We shall own exclusive rights,
                            including all intellectual property rights, and shall be entitled
                            to the unrestricted use and dissemination of these Submissions for
                            any lawful purpose, commercial or otherwise, without
                            acknowledgment or compensation to you. You hereby waive all moral
                            rights to any such Submissions, and you hereby warrant that any
                            such Submissions are original with you or that you have the right
                            to submit such Submissions. You agree there shall be no recourse
                            against us for any alleged or actual infringement or
                            misappropriation of any proprietary right in your Submissions.
                        </p>
                        <h5 id="tpwc">9. THIRD-PARTY WEBSITE AND CONTENT</h5>
                        <p>
                            The Site may contain (or you may be sent via the Site) links to
                            other websites ("Third-Party Websites") as well as articles,
                            photographs, text, graphics, pictures, designs, music, sound,
                            video, information, applications, software, and other content or
                            items belonging to or originating from third parties ("Third-Party
                            Content"). Such Third-Party Websites and Third-Party Content are
                            not investigated, monitored, or checked for accuracy,
                            appropriateness, or completeness by us, and we are not responsible
                            for any Third-Party Websites accessed through the Site or any
                            Third-Party Content posted on, available through, or installed
                            from the Site, including the content, accuracy, offensiveness,
                            opinions, reliability, privacy practices, or other policies of or
                            contained in the Third-Party Websites or the Third-Party Content.
                            Inclusion of, linking to, or permitting the use or installation of
                            any Third-Party Websites or any Third-Party Content does not imply
                            approval or endorsement thereof by us. If you decide to leave the
                            Site and access the Third-Party Websites or to use or install any
                            Third-Party Content, you do so at your own risk, and you should be
                            aware these Terms of Use no longer govern. You should review the
                            applicable terms and policies, including privacy and data
                            gathering practices, of any website to which you navigate from the
                            Site or relating to any applications you use or install from the
                            Site. Any purchases you make through Third-Party Websites will be
                            through other websites and from other companies, and we take no
                            responsibility whatsoever in relation to such purchases which are
                            exclusively between you and the applicable third party. You agree
                            and acknowledge that we do not endorse the products or services
                            offered on Third-Party Websites and you shall hold us harmless
                            from any harm caused by your purchase of such products or
                            services. Additionally, you shall hold us harmless from any losses
                            sustained by you or harm caused to you relating to or resulting in
                            any way from any Third-Party Content or any contact with
                            Third-Party Websites.
                        </p>
                        <h5 id="smng">10. SITE MANAGEMENT</h5>
                        <p>
                            We reserve the right, but not the obligation, to: (1) monitor the
                            Site for violations of these Terms of Use; (2) take appropriate
                            legal action against anyone who, in our sole discretion, violates
                            the law or these Terms of Use, including without limitation,
                            reporting such user to law enforcement authorities; (3) in our
                            sole discretion and without limitation, refuse, restrict access
                            to, limit the availability of, or disable (to the extent
                            technologically feasible) any of your Contributions or any portion
                            thereof; (4) in our sole discretion and without limitation,
                            notice, or liability, to remove from the Site or otherwise disable
                            all files and content that are excessive in size or are in any way
                            burdensome to our systems; and (5) otherwise manage the Site in a
                            manner designed to protect our rights and property and to
                            facilitate the proper functioning of the Site.
                        </p>
                        <h5 id="pripol">11. PRIVACY POLICY</h5>
                        <p>
                            We care about data privacy and security. Please review our Privacy
                            Policy.{" "}
                            {/* <button
                // data-dismiss="modal"
                data-toggle="modal"
                data-target="#privPolModal"
                className={termsCSS.buttonLink}
              >
                Study for Your Citizenship Privacy Policy
              </button>{" "} */}
                            By using the Site, you agree to be bound by our Privacy Policy,
                            which is incorporated into these Terms of Use. Please be advised
                            the Site is hosted in the United States . If you access the Site
                            from any other region of the world with laws or other requirements
                            governing personal data collection, use, or disclosure that differ
                            from applicable laws in the United States , then through your
                            continued use of the Site, you are transferring your data to the
                            United States , and you agree to have your data transferred to and
                            processed in the United States.
                        </p>
                        <h5 id="refpol">12. REFUND POLICY</h5>
                        <p>
                            Please review Our Refund Policy. By
                            using the Site, you agree to be bound by our Refund Policy, which
                            is incorporated into these Terms of Service.
                        </p>
                        <h5 id="tte">13. TERM AND TERMINATION</h5>
                        <p>
                            These Terms of Use shall remain in full force and effect while you
                            use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS
                            OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
                            WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE
                            (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
                            REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH
                            OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
                            TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
                            TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE YOUR
                            ACCOUNT ANDÂ ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
                            TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. If we terminate or
                            suspend your account for any reason, you are prohibited from
                            registering and creating a new account under your name, a fake or
                            borrowed name, or the name of any third party, even if you may be
                            acting on behalf of the third party. In addition to terminating or
                            suspending your account, we reserve the right to take appropriate
                            legal action, including without limitation pursuing civil,
                            criminal, and injunctive redress.
                        </p>
                        <h5 id="moint">14. MODIFICATIONS AND INTERRUPTIONS</h5>
                        <p>
                            We reserve the right to change, modify, or remove the contents of
                            the Site at any time or for any reason at our sole discretion
                            without notice. However, we have no obligation to update any
                            information on our Site. We also reserve the right to modify or
                            discontinue all or part of the Site without notice at any time. We
                            will not be liable to you or any third party for any modification,
                            price change, suspension, or discontinuance of the Site. We cannot
                            guarantee the Site will be available at all times. We may
                            experience hardware, software, or other problems or need to
                            perform maintenance related to the Site, resulting in
                            interruptions, delays, or errors. We reserve the right to change,
                            revise, update, suspend, discontinue, or otherwise modify the Site
                            at any time or for any reason without notice to you. You agree
                            that we have no liability whatsoever for any loss, damage, or
                            inconvenience caused by your inability to access or use the Site
                            during any downtime or discontinuance of the Site. Nothing in
                            these Terms of Use will be construed to obligate us to maintain
                            and support the Site or to supply any corrections, updates, or
                            releases in connection therewith.
                        </p>
                        <h5 id="govl">15. GOVERNING LAW</h5>
                        <p>
                            These Terms shall be governed by and defined following the laws of
                            Jamaica. Study for Your Citizenship and yourself irrevocably
                            consent that the courts of Jamaica shall have exclusive
                            jurisdiction to resolve any dispute which may arise in connection
                            with these terms.
                        </p>
                        <h5 id="dres">16. DISPUTE RESOLUTION</h5>
                        <h6>Informal Negotiations</h6>
                        <p>
                            To expedite resolution and control the cost of any dispute,
                            controversy, or claim related to these Terms of Use (each
                            "Dispute" and collectively, the "Disputes") brought by either you
                            or us (individually, a "Party" and collectively, the "Parties"),
                            the Parties agree to first attempt to negotiate any Dispute
                            (except those Disputes expressly provided below) informally for at
                            least fifty (50) days before initiating arbitration. Such informal
                            negotiations commence upon written notice from one Party to the
                            other Party.
                        </p>
                        <h6>Restrictions</h6>
                        <p>
                            The Parties agree that any arbitration shall be limited to the
                            Dispute between the Parties individually. To the full extent
                            permitted by law, (a) no arbitration shall be joined with any
                            other proceeding; (b) there is no right or authority for any
                            Dispute to be arbitrated on a class-action basis or to utilize
                            class action procedures; and (c) there is no right or authority
                            for any Dispute to be brought in a purported representative
                            capacity on behalf of the general public or any other persons.
                        </p>
                        <h6>Exceptions to Informal Negotiations and Arbitration</h6>
                        <p>
                            The Parties agree that the following Disputes are not subject to
                            the above provisions concerning informal negotiations and binding
                            arbitration: (a) any Disputes seeking to enforce or protect, or
                            concerning the validity of, any of the intellectual property
                            rights of a Party; (b) any Dispute related to, or arising from,
                            allegations of theft, piracy, invasion of privacy, or unauthorized
                            use; and (c) any claim for injunctive relief. If this provision is
                            found to be illegal or unenforceable, then neither Party will
                            elect to arbitrate any Dispute falling within that portion of this
                            provision found to be illegal or unenforceable and such Dispute
                            shall be decided by a court of competent jurisdiction within the
                            courts listed for jurisdiction above, and the Parties agree to
                            submit to the personal jurisdiction of that court.
                        </p>
                        <h5 id="cor">17. CORRECTIONS</h5>
                        <p>
                            There may be information on the Site that contains typographical
                            errors, inaccuracies, or omissions, including descriptions,
                            pricing, availability, and various other information. We reserve
                            the right to correct any errors, inaccuracies, or omissions and to
                            change or update the information on the Site at any time, without
                            prior notice.
                        </p>
                        <h5 id="pap">18. PURCHASES</h5>
                        <p>
                            Pricing and availability of all Content displayed through this
                            Site are subject to change at any time prior to purchase. Once the
                            digital goods you purchase from this Site are succesfully
                            delivered to your verified email address or available for
                            download, your payment method will be charged.
                        </p>
                        <h5 id="disc">19. DISCLAIMER</h5>
                        <p>
                            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
                            THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE
                            RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
                            WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND
                            YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
                            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                            AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
                            ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE'S CONTENT OR THE
                            CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
                            LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
                            INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
                            PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
                            ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
                            USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                            AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION
                            OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS,
                            VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
                            THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
                            OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE
                            OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                            TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT
                            WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
                            PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
                            THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
                            APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE
                            WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
                            ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
                            PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
                            THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                            JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. (7) WE ARE NOT
                            RESPONSIBLE FOR ACQUIRING YOUR CITIZENSHIP FOR YOU. THE ONUS IS ON
                            YOU TO DO SO.
                        </p>
                        <h5 id="limli">20. LIMITATIONS OF LIABILITY</h5>
                        <p>
                            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
                            LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                            CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
                            DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
                            OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE
                            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING
                            ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU
                            FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION,
                            WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO
                            US DURING THE ONE (1) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
                            ARISING. HAVE ADDITIONAL RIGHTS.
                        </p>
                        <h5 id="indem">21. INDEMNIFICATION</h5>
                        <p>
                            You agree to defend, indemnify, and hold us harmless, including
                            our subsidiaries, affiliates, and all of our respective officers,
                            agents, partners, and employees, from and against any loss,
                            damage, liability, claim, or demand, including reasonable
                            attorneys' fees and expenses, made by any third party due to or
                            arising out of: (1) use of the Site; (2) breach of these Terms of
                            Use; (3) any breach of your representations and warranties set
                            forth in these Terms of Use; (4) your violation of the rights of a
                            third party, including but not limited to intellectual property
                            rights; or (5) any overt harmful act toward any other user of the
                            Site with whom you connected via the Site. Notwithstanding the
                            foregoing, we reserve the right, at your expense, to assume the
                            exclusive defense and control of any matter for which you are
                            required to indemnify us, and you agree to cooperate, at your
                            expense, with our defense of such claims. We will use reasonable
                            efforts to notify you of any such claim, action, or proceeding
                            which is subject to this indemnification upon becoming aware of
                            it.
                        </p>
                        <h5 id="ud">22. USER DATA</h5>
                        <p>
                            We will maintain certain data that you transmit to the Site for
                            the purpose of managing the performance of the Site, as well as
                            data relating to your use of the Site. Although we perform regular
                            routine backups of data, you are solely responsible for all data
                            that you transmit or that relates to any activity you have
                            undertaken using the Site. You agree that we shall have no
                            liability to you for any loss or corruption of any such data, and
                            you hereby waive any right of action against us arising from any
                            such loss or corruption of such data.
                        </p>
                        <h5 id="ecg">
                            23. ELECTRONIC COMMUNICATIONS, GOODS, TRANSACTIONS, AND SIGNATURES
                        </h5>
                        <p>
                            Visiting the Site, sending us emails, and completing online forms
                            constitute electronic communications. You consent to receive
                            electronic communications, electronic goods, and you agree that
                            all agreements, notices, disclosures, and other communications we
                            provide to you electronically, via email and on the Site, satisfy
                            any legal requirement that such communication be in writing. YOU
                            HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
                            ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
                            POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US
                            OR VIA THE SITE. You hereby waive any rights or requirements under
                            any statutes, regulations, rules, ordinances, or other laws in any
                            jurisdiction which require an original signature or delivery or
                            retention of non-electronic records, or to payments or the
                            granting of credits by any means other than electronic means.
                        </p>
                        <h5 id="cont">24. CONTACT US</h5>
                        <p>
                            In order to resolve a complaint regarding the Site or to receive
                            further information regarding use of the Site, please contact us
                            at:{" "}
                            <strong>
                                <a href="mailto:support@studyfyc.com">support@studyfyc.com</a>
                            </strong>
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsModal;
