import React from 'react'
import Card from './card/card'
import additionalConCss from './additionalcontent.module.css';

const AdditionalContent = function (): React.ReactElement {
    return (
        <div id={additionalConCss.mainCon}>
            <div aria-label="Additional Content" className="row" style={{padding: '15px'}}>
                <Card imgsrc={'mail_icon.png'} altimgname={'mail_icon'} content={'Build your knowledge daily. Receive free quiz questions in your email everyday for 5 days. Request the solutions you want!'}/>
                <Card imgsrc={'renew_icon.png'} altimgname={'renew_icon'} content={'Renew your subscription as many times as you want. Renewal is free!'}/>
                <Card imgsrc={'quiz_icon.png'} altimgname={'quiz_icon'} content={'Master all our practice quizzes with assistance from our insightful quiz solutions. Start now!'}/>
            </div>
        </div>
    )
}

export default AdditionalContent