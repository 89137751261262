import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { QuizTaker, registerQuizTaker } from '../../redux/actions/register'
import Notification from '../notification/notification'
import mainCSS from './maincontent.module.css'
import $ from 'jquery'
import { AppDispatch, ApplicationState } from '../..'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import PrivacyModal from '../privacymodal/privacymodal'
import TermsModal from '../termsmodal/termsmodal'
import RefundModal from '../refundmodal/refundmodal'

// const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

const MainContent = function (): React.ReactElement {


    // const [showArrow, setShowArrow] = useState(true)

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll)
    // })

    // const handleScroll = (e: Event) => {
    //     e.preventDefault()
    //     if (window.scrollY > 100) {
    //         setShowArrow(false)
    //     }
    //     if (window.scrollY <= 100) {
    //         setShowArrow(true)
    //     }

    // }

    const handleArrowTap = useCallback(() => {
        // Watch Performance
        $('html').animate({ scrollTop: window.screen.height }, 1000)
        // window.scrollTo({
        //     top: window.screen.height,
        //     behavior: 'smooth'
        // })
    }, [])


    const registerStatus = useSelector((state: ApplicationState) => state.registerQuizTaker)

    useEffect(() => {
        if (registerStatus.success) {
            setFirstName('')
            setLastname('')
            setEmail('')
            setCountry('')
        }
    }, [registerStatus.success])

    const dispatch = useDispatch<ThunkDispatch<AppDispatch, void, AnyAction>>()

    const [firstname, setFirstName] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [country, setCountry] = useState('')

    // TODO: Error display
    // const [firstnameErr, setFirstNameErr] = useState('')
    // const [lastnameErr, setLastnameErr] = useState('')
    // const [emailErr, setEmailErr] = useState('')
    // const [countryErr, setCountryErr] = useState('')

    const handleFirstnameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        setFirstName(e.target.value)
    }, [])

    const handleLastnameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        setLastname(e.target.value)
    }, [])

    const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        setEmail(e.target.value)
    }, [])

    const handleCountryChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        setCountry(e.target.value)
    }, [])

    const handleSubmit = useCallback((e: SyntheticEvent) => {
        if (firstname === '' || lastname === '' || email === '' || country === '') return false
        e.preventDefault()

        if(typeof grecaptcha === 'undefined') {
            grecaptcha = {} as ReCaptchaV2.ReCaptcha & {
                enterprise: ReCaptchaV2.ReCaptcha;
            }
        }

        grecaptcha.ready = function(cb) {
            if (typeof grecaptcha === 'undefined') {
                // window.__grecaptcha_cfg is a global variable that stores reCAPTCHA's
                // configuration. By default, any functions listed in its 'fns' property
                // are automatically executed when reCAPTCHA loads.
                const c = '___grecaptcha_cfg';
                window[c] = window[c] || {};
               (window[c]['fns'] = window[c]['fns']||[]).push(cb)
            } else {
                cb()
            }
        }

        grecaptcha.ready(async function() {
            const token = await grecaptcha.execute('6LdGZR0jAAAAAE1Ur6ry2b0lWzqdDZ6UxOeEX4OK', {action: 'register'})
            const quizTaker: QuizTaker = {
                firstname: firstname,
                lastname: lastname,
                email: email,
                country: country,
                recapToken: token
            }
            dispatch(registerQuizTaker(quizTaker))
        })


    }, [country, dispatch, email, firstname, lastname])

    return (
        <div className={`row d-flex ${mainCSS.parent}`} style={{ backgroundImage: `url(flagstosky.jpg)` }}>
            <Notification />
            <div className="col-sm-12 d-flex" style={{justifyContent: "center", marginTop: "10px"}}>
                <div className={mainCSS.logoContainer} style={{zIndex: 1}}>
                    <img src='./sfyc_logo_white.svg' width="45px" height="45px" alt="mail sent icon" />
                </div>
            </div>
            <div className={`col-sm-6 d-flex flex-column ${mainCSS.columnOne}`} style={{ minHeight: `${vh}px` }}>
                <div className={`col-md-12 ${mainCSS.columnOneElement}`}>
                    <div className="col-md-12" style={{ padding: '0px' }}>
                        <div id={mainCSS.headingContainer}>
                            <h1 aria-label="Study for Your Citizenship" id={mainCSS.heading}>Study for Your Citizenship</h1>
                        </div>
                        <div id={mainCSS.subHeadingContainer}>
                            <h3 aria-label="A website dedicated to helping you pass your naturalization exam" id={mainCSS.subHeading}>
                                A website dedicated to helping you pass your naturalization exam
                            </h3>
                        </div>
                        <div className={mainCSS.info} style={{ fontSize: '12px' }}>
                            {/* <p className={mainCSS.infoPara}>
                                Naturalization is the process by which U.S. citizenship is granted to a lawful permanent
                                resident after meeting the requirements established by Congress in the Immigration and Nationality Act (INA).
                                <i style={{ color: 'white' }}> &nbsp; &nbsp; -uscis.gov</i>
                            </p> */}
                            <p className={mainCSS.infoPara}>
                                Naturalization is the legal act or process by which a non-citizen of a country may acquire citizenship or nationality
                                of that country.
                            </p>
                        </div>
                    </div>
                    {/* Add facts stating number of persons who gain citizenship through naturalization */}
                    {/* Add down pointing arrow on mobile devices */}
                </div>
                <div id={mainCSS.arrowContainer} className="arrowContainer" onClick={handleArrowTap}>
                    <div id={mainCSS.arrowBox}>
                        <img src="down_arrow.png" alt="down_arrow" width="20" height="20" />
                    </div>
                </div>
            </div>
            <div className={`col-sm-6 d-flex justify-content-center ${mainCSS.columnTwo}`} style={{ minHeight: `${vh}px` }}>
                <div className={`d-flex justify-content-center align-items-center flex-column ${mainCSS.columnTwoElement}`} style={{ zIndex: 1, width: '100%' }}>
                    <div className={mainCSS.formHeadingContainer}>
                        <h4 aria-label="Subscribe Today!" id={mainCSS.formHeading}>Subscribe Today!</h4>
                        {registerStatus.isLoading ? <div className={mainCSS.loader}></div> : <div></div>}
                    </div>
                    <form onSubmit={handleSubmit} className={`d-flex flex-column ${mainCSS.form}`} id="RegistrationForm">
                        <div className={`form-group ${mainCSS.fg}`}>
                            <label className={mainCSS.formLabel} htmlFor="firstname">First Name</label>
                            <input id="firstname" aria-label="Please enter your first name" maxLength={30} required value={firstname} type="text" onChange={handleFirstnameChange} className={`form-control ${mainCSS.formControl}`} />
                        </div>
                        <div className={`form-group ${mainCSS.fg}`}>
                            <label className={mainCSS.formLabel} htmlFor="lastname">Last Name</label>
                            <input id="lastname" aria-label="Please enter your last name" maxLength={30} required value={lastname} type="text" onChange={handleLastnameChange} className={`form-control ${mainCSS.formControl}`} />
                        </div>
                        <div className={`form-group ${mainCSS.fg}`}>
                            <label className={mainCSS.formLabel} htmlFor="email">Email</label>
                            <input id="email" aria-label="Please enter your email" maxLength={50} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required value={email} type="email" onChange={handleEmailChange} className={`form-control ${mainCSS.formControl}`} />
                        </div>
                        <div className={`form-group ${mainCSS.fg}`}>
                            <label className={mainCSS.formLabel} htmlFor="inputState">Country</label>
                            <select aria-label="Please select the country in which you plan to acquire your citizenship" required value={country} id="inputState" onChange={handleCountryChange} className={`form-control ${mainCSS.formControl}`}>
                                <option value="">Please select the country in which you plan to acquire your citizenship</option>
                                <option value="USA">United States of America</option>
                            </select>
                        </div>
                        {/* <button type="submit" aria-label="Subscribe" onClick={handleSubmit} className="btn btn-primary mb-2" id={mainCSS.formButton}>Subscribe</button> */}
                        <button disabled={registerStatus.isLoading} type="submit" aria-label="Subscribe" onClick={handleSubmit} className="btn btn-primary mb-2" id={mainCSS.formButton}>Subscribe</button>
                    </form>
                    <div id={mainCSS.termsCon}>
                        <p className={mainCSS.termsText}>By subscribing, you agree to Study for Your Citizenship <button data-toggle='modal' data-target='#termsModal' className={mainCSS.buttonLink}>Terms of Service</button>, <button data-toggle='modal' data-target='#privPolModal' className={mainCSS.buttonLink}>Privacy Policy</button> and <button data-toggle='modal' data-target='#refPolModal' className={mainCSS.buttonLink}>Refund Policy</button>.</p>
                    </div>
                </div>
            </div>
            <div id={mainCSS.backDrop}></div>
            <PrivacyModal/>
            <TermsModal/>
            <RefundModal/>
        </div>
    )
}

export default MainContent