import { AppDispatch, ApplicationState } from "../.."

export enum ShowNotif {
    true = "true",
    false = "false",
    null = "null"
}
//Synchronous actions
export const showNotification = (notificationMessage: string, colour: string): { type: string, payload: NotificationState } => ({
    type: 'SHOW_NOTIFICATION',
    payload: {
        showNotification: ShowNotif.true,
        notificationMessage,
        colour
    }
})

export const hideNotification = (): { type: string, payload: NotificationState } => ({
    type: 'HIDE_NOTIFICATION',
    payload: {
        showNotification: ShowNotif.false,
        notificationMessage: '',
        colour: ''
    }
})

export const resetNotification = (): { type: string, payload: NotificationState } => ({
    type: 'RESET_NOTIFICATION',
    payload: {
        showNotification: ShowNotif.null,
        notificationMessage: '',
        colour: ''
    }
})

export interface NotificationState {
    showNotification: string,
    notificationMessage: string,
    colour: string
}

export enum NotificationBarColours {
    blue = 'rgb(7, 119, 223)',
    green = 'rgb(37, 241, 10)',
    red = 'rgb(241, 11, 11)'
}

// Used to store timeout value so that every call to the function 'sleep' can call clearTimeout on it
// This prevents the notfication from closing prematurely
let timeOut: NodeJS.Timeout;

// Custom function which allows the function to sleep
function sleep(ms: number) {
    clearTimeout(timeOut)
    return new Promise((resolve) => timeOut = setTimeout(resolve, ms))
}


//Asynchronous actions
export const handleNotification = ({ notificationMessage, colour }: { notificationMessage: string, colour: string }) => {
    return async (dispatch: AppDispatch, getState: () => ApplicationState): Promise<{ type: string; payload: NotificationState }> => {
        const { notification } = getState()
        if (notification.showNotification === 'true') {
            dispatch(hideNotification())
            await sleep(500)
            dispatch(showNotification(notificationMessage, colour))
            await sleep(7000)
            return dispatch(hideNotification())
        }
        dispatch(showNotification(notificationMessage, colour))
        await sleep(7000)
        return dispatch(hideNotification())
    }
}
