import { NotificationState } from "../actions/notification"

export const notificationReducer = (state = { showNotification: 'null', notificationMessage: '', colour: '' }, action: { type: string, payload: { showNotification: string, colour: string, notificationMessage: string } }): NotificationState => {
    switch (action.type) {
        case 'SHOW_NOTIFICATION':
            return action.payload
        case 'HIDE_NOTIFICATION':
            return action.payload
        case 'RESET_NOTIFICATION':
            return action.payload
        default:
            return state
    }
}
