import React from 'react'
import cardCSS from './card.module.css'

const Card = function ({ content, imgsrc, altimgname }: { content: string, imgsrc: string, altimgname: string }): React.ReactElement {
    return (
        <div className={`col-sm-4 ${cardCSS.parent}`}>
            <div className={cardCSS.container}>
                <div className={`d-flex align-items-center justify-content-center flex-column  ${cardCSS.containerBox}`}>
                    <div className={cardCSS.imgContainer}>
                        <img src={imgsrc} alt={altimgname} />
                    </div>
                    <div className={cardCSS.divider}></div>
                    <div className={cardCSS.contentContainer}>
                        <div aria-label={content} className={cardCSS.content}>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card