import React from "react";
import footerCSS from "./footer.module.css";

const Footer = function (): React.ReactElement {
  return (
    <div className={footerCSS.footerCon}>
      <p id={footerCSS.footerTextAtt}>Photo by <a href="https://unsplash.com/@sebastiano_piazzi?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer">Sebastiano Piazzi</a> on <a href="https://unsplash.com/@sebastiano_piazzi?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer">Unsplash</a></p>
      <p className={footerCSS.footerText}>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> apply.</p>
      <p className={footerCSS.footerText}>Study for Your Citizenship <button data-toggle='modal' data-target='#termsModal' className={footerCSS.buttonLink}>Terms of Service</button>, <button data-toggle='modal' data-target='#privPolModal' className={footerCSS.buttonLink}>Privacy Policy</button> and <button data-toggle='modal' data-target='#refPolModal' className={footerCSS.buttonLink}>Refund Policy</button>.</p>
      <p className={footerCSS.footerText}>Copyright &copy; 2023 Study for Your Citizenship. All Rights Reserved.</p>
    </div>
  );
};

export default Footer;
