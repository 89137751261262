import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import reducers from './redux/reducers'

const store = createStore(reducers, compose(applyMiddleware(thunk)))
export type AppDispatch = typeof store.dispatch
const appState = store.getState()
export type ApplicationState = typeof appState


const container = document.getElementById('root') || document.createElement("div")
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
