import React from 'react'

const RefundModal = function (): React.ReactElement {
    return (
        <div className='modal fade' id="refPolModal" tabIndex={-1} aria-labelledby="refPolModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title" id="refPolModalLabel">Study for Your Citizenship Refund Policy</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h3>Refund Policy</h3>
                        <p>Last updated: January 14, 2023</p>
                        <h4>Your Consent</h4>
                        <p>By using our platform, subscribing to our service, or making a purchase, you hereby consent to our Refund
                            Policy and agree to its terms.
                        </p>
                        <h4>Changes to Our Refund Policy</h4>
                        <p>
                            Should we update, amend or make any changes to this document so that they accurately reflect our Service and
                            policies, unless otherwise required by law, those changes will be posted here. Then, if you continue to
                            use the Service, you will be bound by the updated Return & Refund Policy. If you do not want to agree to this or any
                            updated Return & Refund Policy, you desist from using the Site and unsubscribe.
                        </p>
                        <h4>Digital Products</h4>
                        <p>Digital products which are purchased which are downloadable such as ebooks and pdf files are not eligible for return.
                            To obtain a refund, you will be required to supply your receipt and transaction ID via email within 1 day of purchase.</p>
                        <h4>Contact us</h4>
                        <p>
                            Contact us via email at: <a href="mailto:support@studyfuc.com">support@studyfyc.com</a>
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RefundModal